<template>
  <div >
    <div class="mapLeft" :style="{width: fullState? '0' : '350px'}">
      <div class="Putaway"  @click="Putaway()" :style="{right: fullState? '3px' : '340px'}">
        <img src="@/assets/btn.png" :style="{transform:fullState? 'rotate(180deg)': 'rotate(0deg)'}">
      </div>
     <el-form ref="searchForm" class="searchForm"  :model="searchForm" label-width="80px">
          <!-- <el-form-item label="巡检片区" prop="networkId">
            <el-select v-model="searchForm.networkId" size="mini" style="width: 100%;" clearable @change="setNet">
              <el-option
                  v-for="item in networkStatus"
                  :key="item.value"
                  :label="item.network_name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item> -->
       <el-form-item label="使用状态" prop="equipmentStatus">
         <el-select multiple v-model="equipmentStatuses" size="mini" style="width: 100%" clearable  @change="reloadList">
           <el-option
               v-for="item in UserStatus"
               :key="item.value"
               :label="item.name"
               :value="item.value">
           </el-option>
         </el-select>
       </el-form-item>
       <el-form-item label="设备类型" prop="equipmentType">
         <el-select multiple v-model="equipmentTypees" size="mini" style="width: 100%" clearable  @change="reloadList">
           <el-option
               v-for="item in DeviceTypeArray"
               :key="item.code"
               :label="item.name"
               :value="item.code">
           </el-option>
         </el-select>
       </el-form-item>
          <el-form-item label="设备名称" prop="searchText" size="mini">
            <el-input v-model="searchForm.searchText" placeholder="请输入设备名称"></el-input>
          </el-form-item>         
          <el-form-item label-width="0px" class="searchBt">
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-search"
              class="searchBtn"
              @click="reloadList"
              >查询</el-button
            >
            <el-button
              size="mini"
              type="primary"
              class="searchBtn"
              @click="addPoint"
              >新建设备</el-button
            >
          </el-form-item>
        </el-form>
        <div>
            <el-table
              @row-click="gotoEdit"
              ref="tableList"
              :data="tableData"
              height="500"
              style="width: 100%;margin: 15px 0;"
              border
              :stripe="true"
            >
              <el-table-column prop="equipmentName" label="设备名称" align="center" >
                <template slot-scope="{ row }">
                  <span class="blue">{{row.equipmentName}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="equipmentStatus" label="状态" :formatter="setformatter" align="center">
              </el-table-column>
              <el-table-column prop="checkingPoint" label="巡检点" align="center">
                <template slot-scope="{ row }">
                  <el-switch v-model="row.checkingPoint" @change="(val)=>{return checkPoint(val, row)}" :active-value="1" :inactive-value="0"></el-switch>              
                </template>
              </el-table-column>
              <el-table-column prop="isPunch" label="打卡点" align="center">
                <template slot-scope="{ row }">
                  <el-switch :disabled="row.checkingPoint == 0" v-model="row.isPunch"  @change="(val)=>{return clockPoint(val, row)}"  :active-value="0" :inactive-value="1"></el-switch>
                </template>
              </el-table-column>
              
            </el-table>
          <div class="pageBox">
            <el-pagination
                small
                :current-page="pageParams.current"
                :background="true"
                :page-sizes="[10, 30, 50, 100]"
                :page-size="pageParams.size"
                layout=" prev, pager, next"
                :total="total"
                :page-count="3"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>

        </div>
    </div>
    <div class="mapRight" ref="mapRightRef" id="mapRightId">
      <subMap ref="subMap" :modeal='1' :layer-name="layerName" :layers="layers" :layer-code="layerCode" @setLocation="setLocation"/>
      <equipmentForm ref="formBox" :showForm="showForm" @save="save" @closePDraw="closePDraw" :addForm='addForm'/>
    </div>
  </div>
</template>

<script>
import { LoadMixin } from '@/common/amapLoad'
import subMap from '@/views/mapTool/subMap'
import { getEquipmentPage,setcheckpoint,equipmentsDetail } from '@/apis/commonType'
import equipmentForm from '@/views/gisMap/commonForm/equipmentForm'
import {getNetwork} from "../../RequestPort/maintenance/task";
import {SelectDic} from "../../RequestPort/equipment/line";
import {getDict} from "../../apis/commonType";
export default {
  name: 'GisMap',
  components: {subMap ,equipmentForm},
  mixins: [LoadMixin],
  data() {
    return {
      name: '管线',
      layers:"'patrol_equipments'",
      // ,'patrol_networks'
      layerName: '',
      layerCode: '',
      pageParams: {
        current: 1,
        size: 100
      },
      pagerCount:3,
      DeviceTypeArray:[],
      networkStatus:[],
      UserStatus:[
        {name:'停用',value:'0'},
        {name:'在用',value:'1'},
        {name:'废弃',value:'2'}
      ],
      total: 0,
      searchForm:{},
      tableData:[],
      addForm:{
        equipmentTime:'',
        equipmentHome:'',
        equipmentName:'',
        equipmentType:'',
        equipmentSpecifications:'',
        equipmentStatus:'',
        equipmentUseTime:'',
        equipmentDepartmentId:'',
        equipmentProducers:'',
        source:1,
        networkId:'',
        lineId:'',
        },
      showForm:false,
      //使用状态
      equipmentStatuses:[],
      // 设备类型
      equipmentTypees:[],
      // 管线管理弹窗的展示隐藏
      fullState:false,
    }
  },
  computed: {},
  mounted() {
   this.getList()
    getNetwork().then(res=>{
      this.networkStatus = res.data
    })
    getDict({parentCode:20000}).then((e)=>{
      this.DeviceTypeArray = e.data
    })
  },
  methods: {
    /**
     * 搜索
     */
    reloadList(){
      this.layers = 'aaa'
      this.searchForm.equipmentStatus = this.equipmentStatuses+''
      this.searchForm.equipmentType = this.equipmentTypees+''
      this.pageParams.current = 1
       this.getList()
    },
    setNet(){   
      let list=this.networkStatus
      list.forEach(item => {
        if(this.searchForm.networkId==item.id){
          this.$eventBus.$emit('showPolygon',item.location)
        }
      });
      this.pageParams.current = 1
      this.getList()
    },
     getCenterPoint(path) {
      var x = 0.0
      var y = 0.0
      for (var i = 0; i < path.length; i++) {
        x = x + parseFloat(path[i][0])
        y = y + parseFloat(path[i][1])
      }
      x = x / path.length
      y = y / path.length

      return [x, y]
    },
    /**
     * 查看详情（点击设备找到对应点和打开编辑页面）
     */
    gotoEdit(row){
      this.closePDraw()
      this.editFun(row);
    },
    /**
     * 点击收回（右侧菜单展示）
     */
    Putaway() {
      this.fullState = !this.fullState
    },
    editFun(item){
    //  this.$refs.subMap.setCenter([item.locations[0],item.locations[1]])
    //  this.$refs.subMap.SetMarkerHighLight([item.locations[0],item.locations[1]])
      let p = {
        equipmentId: item.id // data.item_key
      }
      equipmentsDetail(p).then(res => {
        let data={
            data:res.data.records[0],
            type:'patrol_equipments'
          }
          this.$eventBus.$emit('vectorEdit', data )
      })
    },
    addPoint(){
      this.$message.success('请先在地图上点选位置，然后填写右侧表单')
       this.$refs.subMap.getLocation()
    },
  
      setLocation(point){
      this.addForm.locations=[point.lng, point.lat]
      this.showForm=true
      let lnglat = [point.lng, point.lat]
      var geocoder = new AMap.Geocoder()
      let that=this
      geocoder.getAddress(lnglat, function(status, result) {
        if (result.info === 'OK') {
          that.addForm.equipmentHome=result.regeocode.formattedAddress
        }      
      })
    //  this.addForm.networkId=1
    },
    closePDraw(){
      this.$refs.formBox.resetFields()
      this.$refs.subMap.clearLocation()
      this.showForm=false
    },
    setformatter(row) {     
      let arr = ['停用','在用','废弃']     
      return arr[row.equipmentStatus]
    },
    checkPoint(val,row){
      let parames={
        ids:[row.id],
        setType:val
      }
       setcheckpoint(parames).then(res => {
         if(res.code==200){
           this.$message.success('操作成功')
         }
         this.getList()
      })
    },
    clockPoint(val,row){
      let parames={
        ids:[row.id],
        isPunch:val
      }
      if(row.checkingPoint == 1){
        setcheckpoint(parames).then(res => {
          if(res.code==200){
            this.$message.success('操作成功')
          }
          this.getList()
        })
      }

    },
    save(){
         this.getList()
         this.closePDraw()
         this.$eventBus.$emit('reloadLayer')
    },
    /**
     * 查询设备列表
     */
    getList() {
      let p = {
        ...this.pageParams,
        ...this.searchForm
      }
      getEquipmentPage(p).then(res => {
        this.total = res.data.total
        this.tableData = res.data.records
      })
    },
    handleSizeChange(val) {
      this.pageParams.size = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageParams.current = val
      this.getList()
    },
  }
}
</script>

<style lang="less" scoped>
.pageBox{width:100%;text-align: center}
.blue{color:#409EFF;cursor: pointer;}
.mapLeft {
  height: calc(100vh - 60px);
  width: 420px;
  background: #fff;float:right;
}
.mapRight{
  width: auto;
  margin-left:0px;position: relative;overflow: hidden;
}
.searchForm{margin:15px 15px 0 5px;}
.searchBt{text-align: center;}
.formBox{position:absolute;right:0;top:100px;background: #fff;width: 300px;padding-top:10px;
.t{padding-left:10px;font-size: 16px;}
::v-deep{.el-divider--horizontal{margin-top:10px;margin-bottom:10px}
.el-date-editor.el-input, .el-date-editor.el-input__inner{width: 100%;}
}
}
// 收回按钮
.Putaway {
  background: #737E95;
  position: absolute;
  top: calc((100% - 0.46rem) / 2);
  // right: 5.25rem;
  // right: 340px;
  color: var(--title-text-color);
  display: flex;
  align-items: center;
  font-size: 0.15rem;
  justify-content: center;
  cursor: pointer;
  width: 0.1rem;
  z-index: 8;
  height: 0.56rem;
}
</style>
